.container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.container--xs {
  max-width: 550px;
}
.container--s {
  max-width: 740px;
}
.container--m {
  max-width: 1000px;
}
.container--l {
  max-width: 1200px;
}
.container--xl {
  max-width: 1600px;
}

.oh {
  overflow: hidden;
}

.centerX {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mta {
  margin-top: auto;
}

.p15 {
  padding: 1.5rem;
}

.py15 { padding-top: 1.5rem; }
.py15 { padding-bottom: 1.5rem; }

.outer {
  padding: 3rem 1.5em;
}
@media (min-width: 600px) {
  .outer {
    padding: 3rem 3em;
  }
}

.if { display: inline-flex; }
.fdc { flex-direction: column; }
