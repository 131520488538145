.asset {
  &__thumb-letterbox {
    width: 166.66px;
    height: 97.06px;
  }

  &__thumb-square {
    width: 166.66px;
    height: 166.66px;
  }

  &__preview {
    &:hover {
      .image__block {
        opacity: 0.5;
      }
      .film__images-single-icon {
        opacity: 1;
      }
    }
  }

  &__play.asset__play {
    font-size: 3em;
    height: 42px;
    pointer-events: none;
    width: 42px;
  }

  &__add-to-cart {
    background-color: yellow;
    border: 0;
    color: black;
    font-size: 14px;
    transition: all 0.3s;
    opacity: 0.75;
    padding: 1em 1.5em 1em 2em;

    &:hover {
      opacity: 0.9;
    }

    &__arrow {
      display: flex;
      justify-content: flex-end;
      opacity: 0.7;
      overflow: hidden;
      transition: width 0.3s;
      width: 20px;

      > svg {
        min-width: 42px;
        position: relative;
      }
    }

    &:hover &__arrow {
      opacity: 1;
      width: 30px;
    }
  }
}
