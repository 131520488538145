.orders {
  min-height: calc(100vh - 320px);

  &__col {
    @media (min-width: 960px) {
      margin: 0 30px;
    }

    &:nth-child(1) {
      width: 80px;
      white-space: nowrap;
      @media (max-width: 540px) {
        display: none;
      }
    }

    &:nth-child(2) {
      margin: 0 1vw;
      min-width: 110px;
      width: 120px;
      @media (max-width: 540px) {
        padding-left: 0.25em;
        text-align: left;
        width: 152px;
      }
    }

    &:nth-child(3) {
      min-width: 82px;
      width: 120px;
    }

    &:nth-child(4) {
      min-width: 280px;
      width: 300px;
      @media (max-width: 750px) {
        min-width: 140px;
        padding-right: 1em;

        svg {
          min-width: 32px;
          width: 32px;
        }
      }

      &.orders__body {
        text-align: right;
      }
    }

    &:nth-child(5) {
      justify-content: flex-end;
      margin-left: auto;
      padding-right: 0.5em;
      text-align: right;
      width: 100px;
    }
  }
}

.order-cell {
  padding: 0 40px;
}
