.al{ text-align:left }
.ac{ text-align:center }
.ar{ text-align:right }
.aj{ text-align:justify }
.block{ display:block }
.inline-block{ display:inline-block }
.f{ display:flex }
.fw{ flex-wrap:wrap }
.ais{ align-items:flex-start }
.aie{ align-items:flex-end }
.aic{ align-items:center }
.aib{ align-items:baseline }
.jcs{ justify-content:flex-start }
.jce{ justify-content:flex-end }
.jcc{ justify-content:center }
.jcb{ justify-content:space-between }
.fa{
  flex:1 1 auto;
  min-width:0;
  min-height:0;
}
.rel{ position:relative }
.abs{ position:absolute }
.fix{ position:fixed }
.fill, .top{ top:0 }
.fill, .bottom{ bottom:0 }
.fill, .left{ left:0 }
.fill, .right{ right:0 }
.x{ width:100% }
.y{ height:100% }
html, body{ margin:0 }
*,
*::before,
*::after{
  box-sizing:border-box;
}
a,
button,
[role="button"],
input,
label,
select,
textarea{
  touch-action:manipulation;
}
.mxa, .ma{ margin-left:auto }
.mxa, .ma{ margin-right:auto }
.mya, .ma{ margin-top:auto }
.mya, .ma{ margin-bottom:auto; }
.pt0, .py0, .p0{ padding-top:0 }
.pb0, .py0, .p0{ padding-bottom:0 }
.mt0, .my0, .m0{ margin-top:0 }
.mb0, .my0, .m0{ margin-bottom:0 }
.mt025, .my025, .m025{ margin-top:0.25em }
.mb025, .my025, .m025{ margin-bottom:0.25em }
.ml025, .mx025, .m025{ margin-left:0.25em }
.mr025, .mx025, .m025{ margin-right:0.25em }
.pt025, .py025, .p025{ padding-top:0.25em }
.pb025, .py025, .p025{ padding-bottom:0.25em }
.pl025, .px025, .p025{ padding-left:0.25em }
.pr025, .px025, .p025{ padding-right:0.25em }
.mt05, .my05, .m05{ margin-top:0.5em }
.mb05, .my05, .m05{ margin-bottom:0.5em }
.ml05, .mx05, .m05{ margin-left:0.5em }
.mr05, .mx05, .m05{ margin-right:0.5em }
.pt05, .py05, .p05{ padding-top:0.5em }
.pb05, .py05, .p05{ padding-bottom:0.5em }
.pl05, .px05, .p05{ padding-left:0.5em }
.pr05, .px05, .p05{ padding-right:0.5em }
.mt075, .my075, .m075{ margin-top:0.75em }
.mb075, .my075, .m075{ margin-bottom:0.75em }
.ml075, .mx075, .m075{ margin-left:0.75em }
.mr075, .mx075, .m075{ margin-right:0.75em }
.pt075, .py075, .p075{ padding-top:0.75em }
.pb075, .py075, .p075{ padding-bottom:0.75em }
.pl075, .px075, .p075{ padding-left:0.75em }
.pr075, .px075, .p075{ padding-right:0.75em }
.mt1, .my1, .m1{ margin-top:1em }
.mb1, .my1, .m1{ margin-bottom:1em }
.ml1, .mx1, .m1{ margin-left:1em }
.mr1, .mx1, .m1{ margin-right:1em }
.pt1, .py1, .p1{ padding-top:1em }
.pb1, .py1, .p1{ padding-bottom:1em }
.pl1, .px1, .p1{ padding-left:1em }
.pr1, .px1, .p1{ padding-right:1em }
.z0{ z-index:0 }
.z1{ z-index:100 }
.z2{ z-index:200 }
.z3{ z-index:300 }
.z4{ z-index:400 }
.z5{ z-index:500 }
.z6{ z-index:600 }
.z7{ z-index:700 }
.z8{ z-index:800 }
.z9{ z-index:900 }
.z10{ z-index:1000 }
.button{
  transition:all 0.3s ease-in-out;
  border:1px solid rgba(255, 255, 255, .2);
  background-color:transparent;
  color:white;
  padding:1rem 2rem;
  cursor:pointer;
}
.button:hover{
  background-color:white;
  color:black;
}
:root{
  --black:#000;
  --white:#FFF;
  --gray:#EAEAEA;
  --offblack:#020201;
  --theme:#FFF;
}
.cw{
  color:var(--white);
}
.ct{
  color:var(--theme);
}
.bcw{
  background-color:var(--white);
}
.bcb{
  background-color:var(--black);
}
.bcgm{
  background-color:var(--gray);
}
.bct{
  background-color:var(--theme);
}
.bcob{
  background-color:var(--offblack);
}
.container{
  max-width:1000px;
  margin-left:auto;
  margin-right:auto;
}
.container--xs{
  max-width:550px;
}
.container--s{
  max-width:740px;
}
.container--m{
  max-width:1000px;
}
.container--l{
  max-width:1200px;
}
.container--xl{
  max-width:1600px;
}
.oh{
  overflow:hidden;
}
.centerX{
  left:50%;
  position:relative;
  transform:translateX(-50%);
}
.center{
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}
.mta{
  margin-top:auto;
}
.p15{
  padding:1.5rem;
}
.py15{ padding-top:1.5rem; }
.py15{ padding-bottom:1.5rem; }
.outer{
  padding:3rem 1.5em;
}
@media (min-width: 600px){
  .outer{
    padding:3rem 3em;
  }
}
.if{ display:inline-flex; }
.fdc{ flex-direction:column; }
.header__logo{
  max-width:80px;
  height:auto;
}
.header svg{
  font-size:1.8em;
}
.header__menu > svg{
  cursor:pointer;
  transition:all 0.3s ease-in-out;
  font-size:2.42em;
}
.header__menu:hover > svg{
  opacity:0.6;
}
.header__menu__content{
  transition:all 0.3s ease 0.1s, visibility 0.1s ease 0.4s;
  visibility:hidden;
  opacity:0;
  transform:translateY(80%);
  white-space:nowrap;
}
.header__menu__content > *{
    background-color:#000;
  }
.header__menu__content > a{
    opacity:unset;
  }
.header__menu__content > a:hover{
      color:rgba(255, 255, 255, .6);
    }
.header__menu:hover .header__menu__content, .header__menu__content:hover{
  transition:all 0.3s ease-out;
  opacity:1;
  visibility:visible;
  transform:translateY(100%);
  height:auto;
}
.header__menu:hover .header__menu__content + .header__email, .header__menu__content:hover + .header__email{
    opacity:0;
    transition:opacity 0.3s;
  }
.header__email{
  position:absolute;
  right:0;
  top:40px;
  transition:opacity 0.4s linear 0.3s;
  opacity:1;
  pointer-events:none;
  white-space:nowrap;
}
.image__block{
  transition:all 0.3s ease-in-out;
  opacity:0;
}
.image__block img{
  transition:all 0.3s ease-in-out;
  transition-delay:0s;
  opacity:0;
  object-fit:cover;
  object-position:center center;
}
.image__block.contain img{
  object-fit:contain;
}
.image__block img.is-visible{
  transform:translateY(0px);
  opacity:1;
}
.image__block.is-visible{
  opacity:1;
  transform:translateY(0px);
}
.image__block.background{
  background-color:rgba(255, 249, 236, .5);
}
.is-visible img{
  transform:translateY(0px);
}
.gatsby-image-wrapper{
  width:100%;
  height:100%;
}
.height100{
  height:100%;
}
.contain{
  object-fit:contain;
}
.cover{
  object-fit:cover;
}
body, .akz{
  font-family:'akzidenz-grotesk', 'sans-serif';
}
.border{
  border:1px solid currentColor;
}
.regular{
  font-weight:normal;
}
.akz-e{
  font-family:'akzidenz-grotesk-extended', sans-serif;
  font-weight:300;
  font-style:normal;
}
html, body{
  font-size:14px;
}
.ls{
  letter-spacing:0.2rem;
}
.lh{
  line-height:1.4;
}
.ls-s{
  letter-spacing:0.1rem;
}
a, .link{
  transition:all .3s ease-in-out;
  text-decoration:none;
  color:currentColor;
}
a.link__square:hover,
    a.link__square.active,
    .link.link__square:hover,
    .link.link__square.active{
      border-color:currentColor;
    }
a:hover, .link:hover{
    opacity:0.6;
  }
.hover{
  cursor:pointer;
}
.caps{
  text-transform:uppercase;
}
.underline{
  text-decoration:underline;
}
.x-bold{
  font-weight:800;
}
.heavy{
  font-weight:800;
}
.black{
  font-weight:900;
}
.semi{
  font-weight:600;
}
.no-underline{
  text-decoration:none;
}
.underline-extra{
  margin-bottom:1rem;
}
.underline-extra:after{
    transition:all 0.2s ease-in-out;
    position:absolute;
    content:'';
    bottom:-6px;
    height:1px;
    left:0;
    width:100%;
    background-color:currentColor;
  }
.underline-extra:hover:after{
      transform:translateY(-3px);
    }
p, .p, .article ul, .article ol, .standard__text li,
.article h2, .article h3{
  opacity:0.7;
}
.terms__single ul, .terms__single ol{
    opacity:0.7;
    margin-left:30px;
  }
.terms__single li{
    list-style-type:disc;
  }
li{
  font-size:1.1rem;
}
@media (max-width: 37.5em){
li{
    font-size:18px
}
  }
h1, h2, h3, h4, h5, h6{
  font-style:normal;
  font-weight:300;
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong{
    font-weight:300;
  }
h1, .h1{
  font-size:2.57143rem;
  line-height:1.2;
  font-weight:normal;
}
@media (max-width: 37.5em){
h1, .h1{
    font-size:3rem
}
  }
@media (max-width: 50em){
h1, .h1{
    font-size:3.5rem
}
  }
@media (max-width: 62.5em){
h1, .h1{
    font-size:4rem
}
    h1.h1-display, .h1.h1-display{
      line-height:1.2;
      font-size:4.75rem;
    }
  }
h2, .h2{
  font-size:1.7rem;
  line-height:1.4;
  font-weight:normal;
}
@media (max-width: 50em){
h2, .h2{
    font-size:2.57143rem
}
  }
@media (max-width: 62.5em){
h2, .h2{
    font-size:2.85714rem
}
  }
h2 strong, .h2 strong{
    font-weight:normal;
  }
h2.level-question, .h2.level-question{
    font-size:16px !important;
  }
@media (max-width: 50em){
h2.level-question, .h2.level-question{
      font-size:18px !important
  }
    }
h3, .h3{
  font-size:1.5rem;
  line-height:1.3;
  font-weight:normal;
}
@media (max-width: 37.5em){
h3, .h3{
    font-size:2rem;
    padding-top:1rem
}
  }
@media (max-width: 50em){
h3, .h3{
    font-size:2.3rem;
    line-height:1.2
}
  }
@media (max-width: 62.5em){
h3, .h3{
    line-height:1.4;
    font-size:2.375rem
}
  }
h4, .h4{
  font-size:1.4rem;
  line-height:1.3;
  font-weight:normal;
}
@media (max-width: 37.5em){
h4, .h4{
    font-size:1.4375rem
}
  }
@media (max-width: 62.5em){
h4, .h4{
    font-size:1.85714rem
}
  }
h5, .h5{
  letter-spacing:0.05rem;
  line-height:1.4;
  font-weight:normal;
  font-size:0.9rem;
}
@media (max-width: 37.5em){
h5, .h5{
    line-height:1.5;
    font-size:1.1rem
}
  }
h6{
  font-weight:normal;
  font-size:20px;
  letter-spacing:0.07rem;
  margin-right:6px;
}
@media (max-width: 50em){
h6{
    font-size:26px
}
  }
h2.level-6{
   font-weight:300;
   text-transform:uppercase;
   font-style:normal;
   font-size:16px;
 }
.tracking{
  letter-spacing:0.005rem;
}
.anim.no-delay,
.show.no-delay.up{
  transition-delay:0s !important;
}
small, .small{
  font-size:12px;
  line-height:1.5;
}
.s14, .s14 p{
  font-size:12px;
  word-break:keep-all;
}
@media (max-width: 37.5em){
.s14, .s14 p{
    font-size:14px
}
  }
.s16{
  font-size:14px;
}
@media (max-width: 37.5em){
.s16{
    font-size:16px
}
  }
.s18{
  font-size:16px;
}
@media (max-width: 50em){
.s18{
    font-size:18px
}
  }
.s22{
  font-size:18px;
}
@media (max-width: 50em){
.s22{
    font-size:22px
}
  }
.ws-pl{
  white-space:pre-line;
}
:root{
  --200:200ms;
  --400:400ms;
  --600:600ms;
  --800:800ms;
  --1000:1000ms;
  --ease:ease-in-out;
  --cubic:cubic-bezier(.12,.67,.53,1);
}
.accordion-tab-label{
  display:inline-flex;
  justify-content:space-between;
  align-items:center;
  cursor:pointer;
  transition:opacity 0.3s;
}
.accordion-tab-label:hover{
  opacity:0.7;
}
.accordion-tab-label::after{
  content:"\276F";
  position:relative;
  width:1em;
  height:1em;
  text-align:center;
  margin-left:0.25em;
  color:white;
  transition:all .35s ease-in-out;
  transform-origin:66% 66%;
}
.accordion-tab-content{
  transition:all .35s;
  overflow:hidden;
}
input.accordion-input{
  position:absolute;
  opacity:0;
  z-index:-1;
}
// :checked
input.accordion-input:checked + .accordion-tab-label{
  opacity:0.85;
}
input.accordion-input:checked + .accordion-tab-label::after{
  transform:rotate(90deg);
}
input.accordion-input:checked ~ .accordion-tab-content{
  overflow:visible;
  animation:1s delay-overflow;
}
@keyframes delay-overflow{
  from{ overflow:hidden; }
}
.admin__nav{
    left:-3em;
    position:absolute;
    top:10px;
    width:300px;
  }
.admin__nav__selected{
      background-color:#666 !important;
      pointer-events:none;
    }
.admin__nav__indicator{
      display:none;
    }
.admin__content{
    margin-left:300px;
  }
@media (max-width: 50em){
    .admin__content{
      margin-left:0;
    }

    .admin__nav{
      left:0;
      max-height:100vh;
      overflow-y:auto;
      position:fixed;
      top:100vh;
      transform:translateY(-40px);
      transition:transform ease-in-out 0.35s;
      width:100%;
    }

      .admin__nav:hover{
        transform:translateY(-100%);
        box-shadow:0 -10px 10vw 6vw #222;
      }

      .admin__nav__indicator{
        background-color:#ccc;
        color:#333;
        display:flex;
        font-size:16px;
        height:41px;
        transition:transform ease-in-out 0.35s;
      }

        .admin__nav__indicator > svg{
          transform:scaleX(4) rotateZ(-90deg);
        }

      .admin__nav:hover .admin__nav__indicator{
        transform:rotateX(90deg);
        transform-origin:top;
      }
  }
.admin__user-link{
  animation:shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  animation-delay:0.5s;
}
@keyframes shake{
  10%, 90%{
    transform:translate3d(-1px, 0, 0);
  }

  20%, 80%{
    transform:translate3d(2px, 0, 0);
  }

  30%, 50%, 70%{
    transform:translate3d(-4px, 0, 0);
  }

  40%, 60%{
    transform:translate3d(4px, 0, 0);
  }
}
.asset__thumb-letterbox{
    width:166.66px;
    height:97.06px;
  }
.asset__thumb-square{
    width:166.66px;
    height:166.66px;
  }
.asset__preview:hover .image__block{
        opacity:0.5;
      }
.asset__preview:hover .film__images-single-icon{
        opacity:1;
      }
.asset__play.asset__play{
    font-size:3em;
    height:42px;
    pointer-events:none;
    width:42px;
  }
.asset__add-to-cart{
    background-color:yellow;
    border:0;
    color:black;
    font-size:14px;
    transition:all 0.3s;
    opacity:0.75;
    padding:1em 1.5em 1em 2em;
  }
.asset__add-to-cart:hover{
      opacity:0.9;
    }
.asset__add-to-cart__arrow{
      display:flex;
      justify-content:flex-end;
      opacity:0.7;
      overflow:hidden;
      transition:width 0.3s;
      width:20px;
    }
.asset__add-to-cart__arrow > svg{
        min-width:42px;
        position:relative;
      }
.asset__add-to-cart:hover .asset__add-to-cart__arrow{
      opacity:1;
      width:30px;
    }
@media (min-height: 678px){
.auth__none{
      min-height:66vh
  }
    }
.auth__input{
    max-width:400px;
    font-family:'akzidenz-grotesk', 'sans-serif';
    font-size:1.2rem;
    border:1px solid rgba(255, 255, 255, .2);
    background-color:black;
    color:white;
  }
.auth__group{
    max-width:400px;
  }
.auth__three{
    width:calc(33% - 0.25rem);
  }
select{
  padding:1rem;
  height:40px;
}
.order-processing{
  background-color:rgba(0, 0, 0, .5);
  animation:0.35s fadeIn;
}
.order-processing > div{
    padding:3em 5em 6em;
  }
.order-success-link:hover .order-success-link__arrow{
    opacity:1;
    width:30px;
  }
.checkout{
  border-left:1px solid #444;
  margin-right:0;
  flex-shrink:0;
  overflow-y:auto;
  opacity:1;
  transition:opacity 0.2s;
}
.checkout.hide{
    transition:opacity 0.2s linear 0.4s;
    opacity:0;
  }
@media (max-width: 1180px){
.checkout{
    flex-shrink:0
}
  }
@media (min-width: 1181px){
.checkout{
    max-width:800px
}
  }
@media (max-width: 700px){
.checkout__inner{
    max-width:400px
}
  }
@media (min-width: 701px){
.checkout__inner{
    max-width:780px
}
  }
.checkout__inner.disabled{
    pointer-events:none;
  }
@media (min-width: 701px){
.checkout .input__row{
    display:flex
}

    .checkout .input__row > *{
      max-width:360px;
    }

    .checkout .input__row > * + *{
      margin-left:1em;
    }
  }
.checkout .input__small{
  margin-left:1em;
  max-width:200px;
  width:70%;
}
.checkout .input__small.territory{
    max-width:120px;
  }
.checkout .input__reference{
  width:200px;
}
@media (min-width: 701px){
.checkout .input__reference{
    margin-left:1em
}
  }
.checkout__close{
  height:0;
}
@media (max-width: 1180px){
.checkout__close{
    display:flex
}
  }
@media (min-width: 1181px){
.checkout__close{
    display:none
}
  }
.checkout__header.checkout__header{
  margin-bottom:2.5em;
}
.checkout__header.checkout__header > *{
    flex:1 1 33%;
    white-space:nowrap;
  }
@media (max-width: 700px){
.checkout__header.checkout__header{
    flex-direction:column;
    align-items:flex-start
}

    .checkout__header.checkout__header > h3{
      margin:0;
      padding-top:0.5em;
    }
  }
@media (min-width: 1181px){
.checkout .cart-summary{
    display:none
}
  }
.checkout__modal__overlay{
  animation:fadeIn 0.3s ease-in-out;
  transition:opacity 0.3s ease-in-out;
  background-color:rgba(125, 125, 110, .1);
}
.checkout__modal__overlay.hide{
    opacity:0;
  }
.checkout__modal__overlay.hide > .checkout__modal{
    transform:scale(0.6);
    opacity:0;
  }
@keyframes showModal{
  from{
    opacity:0;
    transform:scale(0.9);
  }
  to{
    opacity:1;
    transform:scale(1);
  }
}
.loading-dots:after{
  overflow:hidden;
  display:inline-block;
  vertical-align:bottom;
  position:absolute;
  text-align:left;
  animation:ellipsis steps(4,end) 1.1s infinite;
  content:"\2026";
  width:1.5em;
}
@keyframes ellipsis{
  from{ width:0; }
}
.checkout__input-group{
  min-height:78px;
  max-width:400px;
}
.checkout__input-group input{
    height:40px;
    padding:0.8em 1em;
  }
.checkout__input-group code{
    color:darkred;
  }
.checkout__input-group textarea{
    padding:0.8em 1em;
    height:60px;
    resize:none;
  }
.checkout__input-group.error > input{
  box-shadow:inset 0 -1px 0 darkred;
  border-bottom-color:darkred;
}
.checkout button:disabled{
    pointer-events:none;
    background-color:#191914;
    color:#777;
  }
.checkout input:disabled{
    background-color:rgba(255, 255, 255, .1);
    color:rgba(255, 255, 255, .5)
  }
.checkout [class^='country-select']{
    background-color:black;
    border:none;
    text-align:left;
  }
.checkout .country-select{
    border:1px solid rgba(255, 255, 255, .2);
    background-color:black;
    color:#dcdcdc;
  }
.checkout .country-select input{
      height:auto;
    }
.checkout .country-select__input{
      color:rgba(255, 255, 255, .5);
      font-weight:normal;
    }
.checkout .country-select__option--is-focused{
      background-color:#dcdcdc;
      color:#222;
    }
.checkout .country-select__menu{
    z-index:2;
    margin:0;
    left:-1px;
    right:-1px;
    width:auto;
  }
.checkout .country-select__menu-list{
    border:1px solid #444;
    border-top-width:0;
    top:-4px;
    padding-top:4px;
  }
.checkout .country-select__single-value,
  .checkout .country-select__placehalder{
    color:#dcdcdc;
  }
.checkout .iti-container{
    width:100%;
  }
.checkout .cart-summary .mx1,
  .checkout .cart-summary .m1{
    margin-left:0;
  }
.hidden__country.hidden__country.hidden__country{
  pointer-events:none;
  height:0;
  opacity:0;
  padding:0;
  margin:0;
  border-width:0;
  position:absolute;
}
.company-id{

  max-width:260px;
}
.auth-id{
  height:64px;
}
.auth-id input{
  height:40px;
  padding:0.5em 1em;
  max-width:200px;
}
.auth-id button{
  padding:0 1em;
}
.auth-id button:disabled{
  border-color:#191914;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
  -webkit-text-fill-color:#FFf;
  -webkit-box-shadow:0 0 0px 1000px #000 inset;
  transition:background-color 5000s ease-in-out 0s;
}
.fade-in{
  animation:fadeIn 0.3s linear;
}
@keyframes fadeIn{
  from{ opacity:0; }
  to{ opacity:1; }
}
.embla{
  overflow:hidden;
}
.embla__container{
  display:flex;
}
.embla__slide{
  flex:0 0 100%;
  min-width:0;
}
.films__header{
  border-bottom:1px solid rgba(255, 255, 255, .4);
}
.films__row{
  transition:all 0.3s ease-in-out;
  cursor:pointer;
  background-color:rgba(255, 255, 255, .05);
  border-bottom:1px solid rgba(255, 255, 255, .4);
}
.films__row:hover{
  background-color:rgba(255, 255, 255, .2);
}
.films__row:hover svg{
    transform:translateX(5px);
  }
.films__row  svg{
  transition:all 0.3s ease-in-out;
  width:46px;
}
.film__link{
    border-bottom:1px solid rgba(0, 0, 0, 0);
  }
.film__link:hover{
      border-bottom:1px solid white;
    }
.film__single{
    opacity:0;
    transition:all 0.3s ease-in-out;
  }
.film__single.loaded{
      opacity:1;
    }
.film__back .rotate{
      transform:rotate(180deg);
    }
.film__back svg{
      width:50px;
      height:auto;
    }
.film__back h5{
      background-color:black;
      transition:all 0.3s ease-in-out;
    }
.film__back:hover h5{
        transform:translateX(-20px);
      }
.film__poster:hover .film__images-single-icon{
        opacity:1;
      }
.film__images{
    margin-top:30px;
    max-width:490px;
  }
.film__images-container{
      max-width:550px;
    }
@media (max-width: 50em){
.film__images-container{
        max-width:362px
    }
      }
.film__images-single{
      width:calc(33.33333% - 1rem);
    }
@media (max-width: 50em){
.film__images-single{
        width:calc(50% - 1rem)
    }
      }
.film__images-single:hover .image__block{
          opacity:0.5;
        }
.film__images-single:hover .film__images-single-icon{
          opacity:1;
        }
.film__images-single-icon{
        transition:all 0.3s ease-in-out;
        opacity:0;
        width:14px;
        height:14px;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
      }
.film__content{
    padding-top:2rem;
  }
.film__content-inner{
      padding-left:1.5rem;
    }
.film__content-inner .button{
        padding:0.75em 1.5em;
      }
.film__content-single{
      width:33.333%;
    }
.film__content-downloads{
      margin-top:1rem;
      padding-top:1rem;
      border-top:1px solid rgba(255, 255, 255, .3);
    }
.film__poster{
    max-width:30%;
  }
.film__preview{
    min-width:50vw;
    width:100%;
    max-width:900px;
    height:50vw;
    max-height:520px;
    border:none;
  }
.modal{
  display:none;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:100;
  background-color:rgba(0, 0, 0, .5);
}
.modal__video{
    width:100%;
    max-width:900px;
    padding:2rem;
  }
.modal__close{
    color:black;
    background:white;
    border-radius:50%;
    width:40px;
    height:40px;
    border:none;
    font-size:30px;
  }
.modal.is-open{
  display:flex;
}
.cart{
  border-left:1px solid #444;
  bottom:0;
  box-shadow:0 0 0 0 black;
  flex-shrink:0;
  max-height:100%;
  max-width:380px;
  width:100%;
  overflow-y:auto;
  opacity:1;
  transition:opacity 0.1s;
}
.cart.closed{
    transition:opacity 0.2s linear 0.4s;
    opacity:0;
  }
@media (max-width: 1180px){
.cart.with-checkout{
    display:none
}
  }
.main{
  transition:all 0.2s ease-in-out;
  min-height:100vh;
  flex-grow:1;
}
@media (max-width: 1200px){
.main{
    flex-shrink:0
}
  }
@media (min-width: 1201px){
.main{
    min-width:1200px
}
  }
.space{
  transition:all 0.33s ease-in-out;
  pointer-events:none;
  width:0;
  flex-shrink:0;
  max-width:100%;
}
.space.show-cart{
  width:380px;
}
.space.show-checkout{
  width:1180px;
}
.cart__items{
  list-style:none;
  overflow-x:hidden;
  padding-left:0;
  position:relative;
}
.cart__price{
  display:inline-block;
  text-align:right;
}
.cart__close{
  color:white;
  font-size:30px;
  height:44px;
  margin-right:0;
  width:44px;
}
.cart__item{
  min-height:50px;
  opacity:0.9;
  transition:padding 0.25s ease-in, opacity 0.6s;
}
.cart__item.p{
    opacity:0.55;
  }
.cart__item:hover{
    padding-right:50px;
  }
.cart__item > div{
    min-height:50px;
  }
.cart-locked .cart__item:hover{
  padding-right:0;
}
.cart__item__loader{
  width:315px;
  opacity:0.55;
  display:inline-flex;
}
.cart__item__delete{
  color:#333;
}
.cart__item__delete > div{
    width:44px;
    height:44px;
  }
.orders{
  min-height:calc(100vh - 320px);
}
@media (min-width: 960px){
.orders__col{
      margin:0 30px
  }
    }
.orders__col:nth-child(1){
      width:80px;
      white-space:nowrap;
    }
@media (max-width: 540px){
.orders__col:nth-child(1){
        display:none
    }
      }
.orders__col:nth-child(2){
      margin:0 1vw;
      min-width:110px;
      width:120px;
    }
@media (max-width: 540px){
.orders__col:nth-child(2){
        padding-left:0.25em;
        text-align:left;
        width:152px
    }
      }
.orders__col:nth-child(3){
      min-width:82px;
      width:120px;
    }
.orders__col:nth-child(4){
      min-width:280px;
      width:300px;
    }
@media (max-width: 750px){
.orders__col:nth-child(4){
        min-width:140px;
        padding-right:1em
    }

        .orders__col:nth-child(4) svg{
          min-width:32px;
          width:32px;
        }
      }
.orders__col:nth-child(4).orders__body{
        text-align:right;
      }
.orders__col:nth-child(5){
      justify-content:flex-end;
      margin-left:auto;
      padding-right:0.5em;
      text-align:right;
      width:100px;
    }
.order-cell{
  padding:0 40px;
}
body,
html{
  background-color:black;
  color:white;
}
.alpha li{
    list-style-type:lower-alpha;
  }
a{
  opacity:0.8;
}
.hidden{
  visibility:hidden;
  opacity:0;
}
li{
  padding:0.5rem 0;
  opacity:0.8;
}
