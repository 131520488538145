.films__header {
  border-bottom: 1px solid rgba(255,255,255, 0.4);
}

.films__row {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-color: rgba(255,255,255, 0.05);
  border-bottom: 1px solid rgba(255,255,255, 0.4);
}

.films__row:hover {
  background-color: rgba(255,255,255, 0.2);
  svg {
    transform: translateX(5px);
  }
}

.films__row  svg {
  transition: all 0.3s ease-in-out;
  width: 46px;
}

.film {
  &__link {
    border-bottom: 1px solid rgba(0,0,0,0);
    &:hover {
      border-bottom: 1px solid white;
    }
  }
  &__single {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.loaded {
      opacity: 1;
    }
  }
  &__back {
    .rotate {
      transform: rotate(180deg);
    }
    svg {
      width: 50px;
      height: auto;
    }
    h5 {
      background-color: black;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      h5 {
        transform: translateX(-20px);
      }
    }
  }
  &__poster {
    &:hover {
      .film__images-single-icon {
        opacity: 1;
      }
    }
  }
  &__images {
    margin-top: 30px;
    max-width: 490px;

    &-container {
      max-width: 550px;
      @media (--800) {
        max-width: 362px;
      }
    }
    &-single {
      width: calc(100% / 3 - 1rem);
      @media (--800) {
        width: calc(50% - 1rem);
      }
      &:hover {
        .image__block {
          opacity: 0.5;
        }
        .film__images-single-icon {
          opacity: 1;
        }
      }
      &-icon {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        width: 14px;
        height: 14px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__content {
    padding-top: 2rem;
    &-inner {
      padding-left: 1.5rem;
      .button {
        padding: 0.75em 1.5em;
      }
    }
    &-single {
      width: 33.333%;
    }
    &-downloads {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid rgba(255,255,255,0.3);
    }
  }
  &__poster {
    max-width: 30%;
  }
  &__preview {
    min-width: 50vw;
    width: 100%;
    max-width: 900px;
    height: 50vw;
    max-height: 520px;
    border: none;
  }
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,0.5);
  &__video {
    width: 100%;
    max-width: 900px;
    padding: 2rem;
  }
  &__close {
    color: black;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    font-size: 30px;
  }
}

.modal.is-open {
  display: flex;
}
