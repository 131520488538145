.admin {
  &__nav {
    left: -3em;
    position: absolute;
    top: 10px;
    width: 300px;

    &__selected {
      background-color: #666 !important;
      pointer-events: none;
    }

    &__indicator {
      display: none;
    }
  }

  &__content {
    margin-left: 300px;
  }

  @media (--800) {
    &__content {
      margin-left: 0;
    }

    &__nav {
      left: 0;
      max-height: 100vh;
      overflow-y: auto;
      position: fixed;
      top: 100vh;
      transform: translateY(-40px);
      transition: transform ease-in-out 0.35s;
      width: 100%;

      &:hover {
        transform: translateY(-100%);
        box-shadow:0 -10px 10vw 6vw #222;
      }

      &__indicator {
        background-color: #ccc;
        color: #333;
        display: flex;
        font-size: 16px;
        height: 41px;
        transition: transform ease-in-out 0.35s;

        > svg {
          transform: scaleX(4) rotateZ(-90deg);
        }
      }

      &:hover &__indicator {
        transform: rotateX(90deg);
        transform-origin: top;
      }
    }
  }
}

.admin__user-link {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  animation-delay: 0.5s;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}