.order-processing {
  background-color: rgba(0, 0, 0, 0.5);
  animation: 0.35s fadeIn;

  > div {
    padding: 3em 5em 6em;
  }
}

.order-success-link {
  &:hover &__arrow {
    opacity: 1;
    width: 30px;
  }
}

.checkout {
  border-left: 1px solid #444;
  margin-right: 0;
  flex-shrink: 0;
  overflow-y: auto;
  opacity: 1;
  transition: opacity 0.2s;

  &.hide {
    transition: opacity 0.2s linear 0.4s;
    opacity: 0;
  }

  /* 800(checkout) + 380(cart) = 1180 */
  @media (max-width: 1180px) {
    flex-shrink: 0
    /* width: 0 */
  }

  @media (min-width: 1181px) {
    max-width: 800px;
  }
}

.checkout__inner {
  @media (max-width: 700px) {
    max-width: 400px;
  }

  @media (min-width: 701px) {
    max-width: 780px;
  }

  &.disabled {
    pointer-events: none;
  }
}

.checkout .input__row {
  @media (min-width: 701px) {
    display: flex;

    > * {
      max-width: 360px;
    }

    > * + * {
      margin-left: 1em;
    }
  }
}

.checkout .input__small {
  margin-left: 1em;
  max-width: 200px;
  width: 70%;

  &.territory {
    max-width: 120px;
  }
}

.checkout .input__reference {
  width: 200px;

  @media (min-width: 701px) {
    margin-left: 1em;
  }
}

.checkout__close {
  height: 0;

  @media (max-width: 1180px) {
    display: flex;
  }

  @media (min-width: 1181px) {
    display: none;
  }
}

.checkout__header.checkout__header {
  margin-bottom: 2.5em;

  > * {
    flex: 1 1 33%;
    white-space: nowrap;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;

    > h3 {
      margin: 0;
      padding-top: 0.5em;
    }
  }
}

.checkout .cart-summary {
  @media (min-width: 1181px) {
    display: none;
  }
}

.checkout__modal__overlay {
  animation: fadeIn 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  background-color: rgba(125, 125,110,0.1);

  &.hide {
    opacity: 0;
  }

  &.hide > .checkout__modal {
    transform: scale(0.6);
    opacity: 0;
  }
}

@keyframes showModal {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.loading-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  position: absolute;
  text-align: left;
  animation: ellipsis steps(4,end) 1.1s infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 1.5em;
}

@keyframes ellipsis {
  from { width: 0; }
}

.checkout__input-group {
  min-height: 78px;
  max-width: 400px;

  & input {
    height: 40px;
    padding: 0.8em 1em;
  }

  & code {
    color: darkred;
  }

  & textarea {
    padding: 0.8em 1em;
    height: 60px;
    resize: none;
  }
}
.checkout__input-group.error > input {
  box-shadow: inset 0 -1px 0 darkred;
  border-bottom-color: darkred;
}

/* .checkout__input-group + .checkout__input-group {
  margin-left: 1em;

  &.ml0 {
    margin-left: 0;
  }
} */

.checkout {
  button:disabled {
    pointer-events: none;
    background-color: #191914;
    color: #777;
  }

  input:disabled {
    background-color: rgba(255, 255, 255, .1);
    color: rgba(255, 255, 255, .5)
  }

  [class^='country-select']{
    background-color: black;
    border: none;
    text-align: left;
  }

  .country-select {
    border: 1px solid rgba(255, 255, 255, .2);
    background-color: black;
    color: #dcdcdc;

    input {
      height: auto;
    }

    &__input {
      color: rgba(255, 255, 255, .5);
      font-weight: normal;
    }

    &__option--is-focused {
      background-color: #dcdcdc;
      color: #222;
    }
  }

  .country-select__menu {
    z-index: 2;
    margin: 0;
    left: -1px;
    right: -1px;
    width: auto;
  }
  .country-select__menu-list {
    border: 1px solid #444;
    border-top-width: 0;
    top: -4px;
    padding-top: 4px;
  }

  .country-select__single-value,
  .country-select__placehalder {
    color: #dcdcdc;
  }

  .iti-container {
    width: 100%;
  }

  .cart-summary .mx1,
  .cart-summary .m1 {
    margin-left: 0;
  }
}

.hidden__country.hidden__country.hidden__country {
  /* visibility: hidden; do not hide this, or it won't pick up the autofill */
  pointer-events: none;
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
  border-width: 0;
  position: absolute;
}

.company-id {

  max-width: 260px;
}

.auth-id {
  height: 64px;
}

.auth-id input {
  height: 40px;
  padding: 0.5em 1em;
  max-width: 200px;
}

.auth-id button {
  padding: 0 1em;
}

.auth-id button:disabled {
  border-color: #191914;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #FFf;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.fade-in {
  animation: fadeIn 0.3s linear;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
