/* Accordion styles */

.accordion-tab-label {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
}
.accordion-tab-label:hover {
  opacity: 0.7;
}

.accordion-tab-label::after {
  content: "\276F";
  position: relative;
  width: 1em;
  height: 1em;
  text-align: center;
  margin-left: 0.25em;
  color: white;
  transition: all .35s ease-in-out;
  transform-origin: 66% 66%;
}

.accordion-tab-content {
  transition: all .35s;
  overflow: hidden;
}

input.accordion-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

// :checked
input.accordion-input:checked + .accordion-tab-label {
  opacity: 0.85;
}
input.accordion-input:checked + .accordion-tab-label::after {
  transform: rotate(90deg);
}
input.accordion-input:checked ~ .accordion-tab-content {
  /* transition: all 1s; */
  overflow: visible;
  animation: 1s delay-overflow;
}

@keyframes delay-overflow {
  from { overflow: hidden; }
}
