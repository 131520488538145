.cart {
  border-left: 1px solid #444;
  bottom: 0;
  box-shadow: 0 0 0 0 black;
  flex-shrink: 0;
  max-height: 100%;
  max-width: 380px;
  width: 100%;
  overflow-y: auto;
  opacity: 1;
  transition: opacity 0.1s;

  &.closed {
    transition: opacity 0.2s linear 0.4s;
    opacity: 0;
  }
}

.cart.with-checkout {
  /* 800(checkout) + 380(cart) = 1180 */
  @media (max-width: 1180px) {
    display: none;
  }
}

.main {
  transition: all 0.2s ease-in-out;
  min-height: 100vh;
  flex-grow: 1;

  @media (max-width: 1200px) {
    flex-shrink: 0
  }

  @media (min-width: 1201px) {
    min-width: 1200px;
  }
}

.space {
  transition: all 0.33s ease-in-out;
  pointer-events: none;
  width: 0;
  flex-shrink:0;
  max-width: 100%;
}

.space.show-cart {
  width: 380px;
}

.space.show-checkout {
  width: 1180px;
}


.cart__items {
  list-style: none;
  overflow-x: hidden;
  padding-left: 0;
  position: relative;

  /* li:before {
    position: absolute;
    width: 90%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    color: #aaa;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
  } */
}

.cart__price {
  display: inline-block;
  text-align: right;
}

.cart__close {
  color: white;
  font-size: 30px;
  height: 44px;
  margin-right: 0;
  width: 44px;
}


.cart__item {
  min-height: 50px;
  opacity: 0.9;
  transition: padding 0.25s ease-in, opacity 0.6s;

  &.p {
    opacity: 0.55;
  }

  &:hover {
    padding-right: 50px;
  }

  > div {
    min-height: 50px;
  }
}

.cart-locked .cart__item:hover {
  padding-right: 0;
}

.cart__item__loader {
  width: 315px;
  opacity: 0.55;
  display: inline-flex;
}

.cart__item__delete {
  color: #333;

  > div {
    width: 44px;
    height: 44px;
  }
}
