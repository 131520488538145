.auth {
  &__none {
    @media (--tall) {
      min-height: 66vh;
    }
  }
  &__input {
    max-width: 400px;
    font-family: 'akzidenz-grotesk', 'sans-serif';
    font-size: 1.2rem;
    border: 1px solid rgba(255,255,255,0.2);
    background-color: black;
    color: white;
  }
  &__group {
    max-width: 400px;
  }
  &__three {
    width: calc(33% - 0.25rem);
  }
}

select {
  padding: 1rem;
  height: 40px;
}