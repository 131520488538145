.header__logo {
  max-width: 80px;
  height: auto;
}

.header svg {
  font-size: 1.8em;
}

.header__menu > svg {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 2.42em;
}

.header__menu:hover > svg {
  opacity: 0.6;
}

.header__menu__content {
  transition: all 0.3s ease 0.1s, visibility 0.1s ease 0.4s;
  visibility: hidden;
  opacity: 0;
  transform: translateY(80%);
  white-space: nowrap;

  > * {
    background-color: #000;
  }

  > a {
    opacity: unset;
    &:hover {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.header__menu:hover .header__menu__content, .header__menu__content:hover {
  transition: all 0.3s ease-out;
  opacity: 1;
  visibility: visible;
  transform: translateY(100%);
  height: auto;

  + .header__email {
    opacity: 0;
    transition: opacity 0.3s;
  }
}


.header__email {
  position: absolute;
  right: 0;
  top: 40px;
  transition: opacity 0.4s linear 0.3s;
  opacity: 1;
  pointer-events: none;
  white-space: nowrap;
}
